<template>
  <div>
    <Top></Top>
    <!-- 首页头部 -->
    <div class="total">
      <div class="content">
        <div class="left">
          <img src="../../assets/index/login.png" alt="" />
        </div>
        <div class="shang">上海溪南</div>
        <div class="flagship">
          <el-popover
            placement="bottom-start"
            title=""
            @show="show"
            @hide="hide"
            width="200"
            trigger="hover"
          >
            <div class="pfirst">交易支持：<span>实时到账</span></div>
            <div class="psecond">买家付款后，交易实时到账</div>
            <div class="pthird">所在地区：<span>上海、上海。</span></div>
            <div class="pforth">店铺主营：<span>普卷、热轧。</span></div>
            <el-button slot="reference"
              >上海宝钢集团旗舰店<i :class="a"></i
            ></el-button>
          </el-popover>
        </div>

        <div class="center">
          <el-input
            placeholder="请输入品名、材质、规格等搜索"
            v-model="searchgoodsinput"
            clearable
          >
          </el-input>
          <router-link to=""
            ><el-button type="danger" icon="el-icon-search"
              >店内搜索</el-button
            ></router-link
          >
        </div>
      </div>
    </div>
    <div class="searmiddle">
      <ul>
        <li>
          <div>钢厂</div>
          <div>
            <a>唐钢</a>
            <a>老本</a>
            <a>日照</a>
            <a>通钢</a>
            <a>梅钢</a>
            <a>日钢</a>
            <a>鞍钢股份</a>
            <a>日照在途</a>
            <a>鞍钢</a>
            <a>中铁</a>
          </div>
          <div>
            <span>+多选</span>
            <span>更多</span>
          </div>
        </li>

        <li>
          <div>材质</div>
          <div>
            <a>Q355B</a>
            <a>Q355A</a>
          </div>
        </li>

        <li>
          <div>存放地</div>
          <div>
            <a>上海</a>
            <a>苏州</a>
          </div>
        </li>
        <li>
          <div>仓库</div>
          <div>
            <a>上海申特库</a>
          </div>
        </li>
        <li>
          <div>件重</div>
          <div>
            <a>2吨以下</a>
            <a>2-5吨</a>
            <a>5-10吨</a>
          </div>
        </li>
        <li class="aaa">
          <div>规格</div>
          <!-- <nav class="input">
              <a
                ><el-input  placeholder="1.0*1250*C"></el-input
              ></a>
              <a><span class="or">或</span></a>
              <div class="seamm">
                <a><span class="aa">厚度:</span></a>

                <span> <el-input></el-input></span>
                <span class="seline">一</span>
                <span> <el-input></el-input></span>
              </div>
              <div class="seamm">
                <a><span class="aa">宽度:</span></a>

                <span> <el-input></el-input></span>
                <span class="seline">一</span>
                <span> <el-input></el-input></span>
              </div>

              <nav class="seamm">
                <a><span class="aa">长度:</span></a>

                <span> <el-input></el-input></span>
                <span class="seline">一</span>
                <span> <el-input></el-input></span>
                <span class="sure">确认</span>
              </nav> -->
          <!-- </nav> -->
        </li>
        <li class="pack" @click="pack">收起<span  :class="icon"></span></li>
      </ul>
    </div>
    <div class="forums">
      <div class="seacontent">
        <div>
          <div class="saimage"></div>
        </div>
        <div class="second">
          <div>高线HPB300970 8 盛隆万钢</div>
          <div>
            上海杭征国际贸易有限公司<span>
              <img class="firimage" src="../../assets/index/hong.png" alt=""
            /></span>
          </div>
        </div>
        <div class="third">
          <div>存放地|杭州市</div>
          <div>库存|20件</div>
        </div>
        <div class="fourth">
          <div><span>￥</span><span>7920</span>/吨</div>
          <div>现价：￥5600/吨</div>
        </div>
        <div class="fifth">2021/12/02</div>
        <div class="six">
          <el-button>查看详情</el-button>
        </div>
      </div>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>
<script>
import Top from "@/components/login/Top.vue";
import Footer from "@/components/login/Footer.vue";
export default {
  data() {
    return {
      searchgoodsinput: "",
      num: 1,
      a: "el-icon-arrow-down",
      icon:'el-icon-arrow-up'
    };
  },
  methods: {
    handleChange(value) {
     
    },
    show() {
      this.a = "el-icon-arrow-up";
    },
    hide() {
      this.a = "el-icon-arrow-down";
    },
    pack(){
      this.icon = 'el-icon-arrow-down'
    }
  },
  components: {
    Top,
    Footer,
  },
};
</script>
<style lang='scss'>
.total {
  border-bottom: 1px solid #e95157;
}
.content {
  width: 60%;
  height: 99px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 740px;
  .left {
    box-sizing: border-box;
    width: 200px;
    // padding: 0px 13px 0px 14px;
    //  border: 1px solid red;
    img {
      width: 140px;
      height: 70px;
      vertical-align: middle;
      //   padding-left: 30px;
      // border: 1px solid red;
    }
  }
  .center {
    width: 700px;
    box-sizing: content-box;
    margin-left: 46px !important;
    height: 40px;
    border-radius: 3px;
    border: 2px solid #e94f55;
    display: flex;
    justify-content: start;
    flex-wrap: nowrap;
    .el-input {
      border: none;
    }

    .el-button--danger {
      border-radius: 0px;
      font-size: 16px;
      color: #e94f55ff;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .right1 {
    width: 138px;
    display: flex;
    padding-left: 29px;
    flex-wrap: wrap;
    // border: 1px solid red;

    .top {
      text-align: center;
      height: 22px;
      width: 120px;
      line-height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.56);
    }
  }
  .shang {
    width: 40px;
    height: 32px;
    text-align: center;
    background: rgb(230, 8, 19);
    border-radius: 2px;
    color: white;
    margin-left: 50px;
  }
  .flagship {
    width: 290px;
    color: #333333;
    font-size: 16px;
    margin-left: 10px;
    cursor: pointer;
  }
}

.searmiddle {
  margin-top: 30px;
  width: 1200px;
  width: 60%;
  margin-left: 400px;
  background: rgba(0, 0, 0, 0.02);
  border: 1px solid rgba(0, 0, 0, 0.1);
  .el-checkbox-group {
    display: flex;
    flex-direction: row;
  }
  .gangc span:nth-of-type(2) {
    color: white;
    background: #e94f55;
    cursor: pointer;
  }
  .gangc span:nth-of-type(1):hover {
    color: #e94f55;
    border: #e94f55 1px solid;
    cursor: pointer;
  }
  .gangc span:nth-of-type(2):hover {
    color: white;
  }
  .firstli a {
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.56);
    padding-left: 5px;
    padding-right: 5px;
  }
  .clear {
    margin-left: 30px;
  }

  ul li {
    list-style: none;
    height: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
  }
  ul li div:nth-of-type(1) {
    width: 90px;
    height: 28px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.56);
    line-height: 38px;
    margin-left: 20px;
  }
  ul li div:nth-of-type(2) {
    width: 880px;
    height: 28px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
    line-height: 38px;
  }
  ul li div:nth-of-type(2) a {
    margin-left: 20px;
  }
  ul li div:nth-of-type(3) {
    display: flex;
    flex-direction: row;
  }
  ul li div:nth-of-type(3) span {
    width: 63px;
    height: 24px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    line-height: 23px;
    text-align: center;
    margin-top: 7px;
    color: rgba(0, 0, 0, 0.56);
    margin-left: 8px;
  }
}
.pfirst,
.pthird,
.pforth {
  color: #333333;
  font-size: 12px;
  line-height: 20px;
}
.psecond,
.pthird span,
.pforth span {
  color: darkgray;
  font-size: 10px;
}
.pfirst span {
  width: 59px;
  height: 20px;
  background: linear-gradient(315deg, #e32531 0%, #fe7378 100%);
  border-radius: 2px;
  font-size: 10px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
}
.searmiddle div:nth-of-type(3) span:hover {
  color: #e94f55;
  border: #e94f55 1px solid;
  cursor: pointer;
}
.pack {
  height: 38px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.56);
  line-height: 38px;
  padding-left: 50%;
}
.pack span{
  line-height: 38px;
  margin-left: 10px;
}
.forums {
  width: 60%;
  height: 99px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 740px;
}
.seacontent {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  margin-left: 30px;
}
.saimage {
  width: 100px;
  height: 100px;
  
}
.second {
  margin-left: 30px;
  .firimage{
    margin-left: 8px;
    width: 15px;
    position: relative;
    top: 3px;
  }
}
.second div:nth-of-type(1) {
  // width: 213px;
  height: 22px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
  line-height: 22px;
  margin-top: 10px;
}
.second div:nth-of-type(2) {
  // width: 213px;
  // height: 14px;
  font-size: 10px;
  font-weight: 300;
  color: rgba(0, 0, 0, 1.6);
  line-height: 14px;
  margin-top: 10px;
}

.third {
  margin-left: 70px;
  margin-top: 20px;
}
.third div:nth-of-type(1) {
  width: 110px;
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
  line-height: 22px;
}
.third div:nth-of-type(2) {
  width: 81px;
  height: 20px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.56);
  line-height: 20px;
  text-align: center;
}
.fourth {
  margin-left: 70px;
  margin-top: 10px;
}
.fourth div:nth-of-type(2) {
  width: 116px;
  height: 20px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.56);
  line-height: 20px;
}
.fourth div:nth-of-type(1) span:nth-of-type(1) {
  width: 14px;
  height: 20px;
  font-size: 12px;
  font-weight: 400;
  color: #d0111a;
  line-height: 20px;
}
.fourth div:nth-of-type(1) span:nth-of-type(2) {
  width: 63px;
  height: 37px;
  font-size: 20px;
  font-weight: 600;
  color: #d0111a;
  line-height: 37px;
}
.fifth {
  width: 90px;
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 20px;
  margin-left: 70px;
}
.six {
  margin-top: 10px;
  margin-left: 70px;
}
.six .el-button {
  width: 107px;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #e94f55;
  color: #e94f55;
}
.six .el-button:hover {
  background: #e94f55;
  color: white;
}
</style>