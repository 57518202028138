var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Top'),_c('div',{staticClass:"total"},[_c('div',{staticClass:"content"},[_vm._m(0),_c('div',{staticClass:"shang"},[_vm._v("上海溪南")]),_c('div',{staticClass:"flagship"},[_c('el-popover',{attrs:{"placement":"bottom-start","title":"","width":"200","trigger":"hover"},on:{"show":_vm.show,"hide":_vm.hide}},[_c('div',{staticClass:"pfirst"},[_vm._v("交易支持："),_c('span',[_vm._v("实时到账")])]),_c('div',{staticClass:"psecond"},[_vm._v("买家付款后，交易实时到账")]),_c('div',{staticClass:"pthird"},[_vm._v("所在地区："),_c('span',[_vm._v("上海、上海。")])]),_c('div',{staticClass:"pforth"},[_vm._v("店铺主营："),_c('span',[_vm._v("普卷、热轧。")])]),_c('el-button',{attrs:{"slot":"reference"},slot:"reference"},[_vm._v("上海宝钢集团旗舰店"),_c('i',{class:_vm.a})])],1)],1),_c('div',{staticClass:"center"},[_c('el-input',{attrs:{"placeholder":"请输入品名、材质、规格等搜索","clearable":""},model:{value:(_vm.searchgoodsinput),callback:function ($$v) {_vm.searchgoodsinput=$$v},expression:"searchgoodsinput"}}),_c('router-link',{attrs:{"to":""}},[_c('el-button',{attrs:{"type":"danger","icon":"el-icon-search"}},[_vm._v("店内搜索")])],1)],1)])]),_c('div',{staticClass:"searmiddle"},[_c('ul',[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('li',{staticClass:"pack",on:{"click":_vm.pack}},[_vm._v("收起"),_c('span',{class:_vm.icon})])])]),_c('div',{staticClass:"forums"},[_c('div',{staticClass:"seacontent"},[_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_c('div',{staticClass:"fifth"},[_vm._v("2021/12/02")]),_c('div',{staticClass:"six"},[_c('el-button',[_vm._v("查看详情")])],1)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('img',{attrs:{"src":require("../../assets/index/login.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',[_vm._v("钢厂")]),_c('div',[_c('a',[_vm._v("唐钢")]),_c('a',[_vm._v("老本")]),_c('a',[_vm._v("日照")]),_c('a',[_vm._v("通钢")]),_c('a',[_vm._v("梅钢")]),_c('a',[_vm._v("日钢")]),_c('a',[_vm._v("鞍钢股份")]),_c('a',[_vm._v("日照在途")]),_c('a',[_vm._v("鞍钢")]),_c('a',[_vm._v("中铁")])]),_c('div',[_c('span',[_vm._v("+多选")]),_c('span',[_vm._v("更多")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',[_vm._v("材质")]),_c('div',[_c('a',[_vm._v("Q355B")]),_c('a',[_vm._v("Q355A")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',[_vm._v("存放地")]),_c('div',[_c('a',[_vm._v("上海")]),_c('a',[_vm._v("苏州")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',[_vm._v("仓库")]),_c('div',[_c('a',[_vm._v("上海申特库")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',[_vm._v("件重")]),_c('div',[_c('a',[_vm._v("2吨以下")]),_c('a',[_vm._v("2-5吨")]),_c('a',[_vm._v("5-10吨")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"aaa"},[_c('div',[_vm._v("规格")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"saimage"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"second"},[_c('div',[_vm._v("高线HPB300970 8 盛隆万钢")]),_c('div',[_vm._v(" 上海杭征国际贸易有限公司"),_c('span',[_c('img',{staticClass:"firimage",attrs:{"src":require("../../assets/index/hong.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"third"},[_c('div',[_vm._v("存放地|杭州市")]),_c('div',[_vm._v("库存|20件")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fourth"},[_c('div',[_c('span',[_vm._v("￥")]),_c('span',[_vm._v("7920")]),_vm._v("/吨")]),_c('div',[_vm._v("现价：￥5600/吨")])])
}]

export { render, staticRenderFns }